import React from 'react';

const footerStyle = {
  backgroundImage: 'url(/images/somethingcooked_footer.jpg)'
};

const Footer = () => (
    <footer id="footerImg" className="footer" style={footerStyle}>
        <div className="footerMask d-flex justify-content-start align-items-end">
          <div className="container text-white">
              <h3 className="display-4 mb-md-3">CONTACT</h3>
              <h6 style={{ color: 'rgb(255,88,26)' }}>
                  EMAIL ADDRESS
                  <span className="mb-md-4" style={{ color: 'white' }}>
                      &nbsp; info@somethingcooked.com
                  </span>
              </h6>
              <h6 style={{ color: 'rgb(255,88,26)' }}>
                  SOCIAL MEDIA
                  <span className="mb-md-4" style={{ color: 'white' }}>
                      &nbsp; &nbsp; &nbsp;@something.cooked
                  </span>
              </h6>

              <a href="https://www.instagram.com/something.cooked" target="_blank" rel="noopener noreferrer"> <button className="fab fa-instagram" aria-label="Instagram"></button></a>
              <button className="fab fa-facebook-f" aria-label="Facebook"></button>
              <button className="fab fa-twitter" aria-label="Twitter"></button>
              <a href="mailto:info@somethingcooked.com" rel="noopener noreferrer"><button className="fab fa-envelope" aria-label="Email"></button></a>
              <br />
              <h5>&copy; 2023 SomethingCooked</h5>
          </div>
        </div>
    </footer>
);

export default Footer;
