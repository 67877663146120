import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const Head = ({ title, description }) => {
    const isProduction = process.env.NODE_ENV === 'production';
    const location = useLocation();
    const fullURL = `${window.location.origin}${location.pathname}`;

    return (
        <Helmet>
            {isProduction && (
                <>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-NVFN8E0R0W"></script>
                    <script>
                        {`
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', 'G-NVFN8E0R0W');
                        `}
                    </script>
                </>
            )}
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content={description} />
            <meta name="keywords" content="Cook, Vegan, West Africa, Tomi, Class, Team-Building, Online Cooking Class, Corporate Activities" />
            <title>{title}</title>
            <link rel="canonical" href={fullURL} />
        </Helmet>
    );
};

export default Head;
