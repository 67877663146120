import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              const snackbar = document.createElement('div');
              snackbar.textContent = "New content is available; please refresh.";
              snackbar.style.position = 'fixed';
              snackbar.style.bottom = '0';
              snackbar.style.left = '50%';
              snackbar.style.transform = 'translateX(-50%)';
              snackbar.style.backgroundColor = '#333';
              snackbar.style.color = '#fff';
              snackbar.style.padding = '10px';
              snackbar.style.borderRadius = '4px';
              snackbar.style.zIndex = '1000'; 
              snackbar.onclick = () => window.location.reload();
              document.body.appendChild(snackbar);
              
              // Optionally, auto-hide the snackbar after a few seconds
              setTimeout(() => document.body.removeChild(snackbar), 5000);
            }
          }
        };
      };
    });
  });
}
