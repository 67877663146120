import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Header from './Header';
import Footer from './Footer';
import Head from './Head';

const Layout = ({ title, description, children }) => (
    <>
        <Head title={title} description={description} />
        
        <Header title={title} />
        {children}
        <Footer />
    </>
);

export default Layout;
