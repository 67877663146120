import React, { useEffect, useState, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import { HelmetProvider } from 'react-helmet-async';
import './styles/somethingcooked.css';
import CsrfTokenContext from './contexts/CsrfTokenContext';

const HomePage = React.lazy(() => import('./pages/HomePage'));
const BookAClassPage = React.lazy(() => import('./pages/BookAClassPage'));
const AboutPage = React.lazy(() => import('./pages/AboutPage'));

function LayoutWrapper({ children }) {
    const location = useLocation();

    
    useEffect(() => {
        // Scroll to the top of the page whenever the route changes
        window.scrollTo(0, 0);
    }, [location.pathname]); // Dependency array with location.pathname ensures this effect runs only when the route changes

    
    const getTitleAndDescription = (path) => {
        switch (path) {
            case '/':
                return { title: 'HOME', description: 'Home page of Something Cooked' };
            case '/bookaclass':
                return { title: 'BOOK A CLASS', description: 'Book a Class with Something Cooked' };
            case '/about':
                return { title: 'ABOUT', description: 'About Something Cooked' };
            default:
                return { title: '', description: '' };
        }
    }

    const { title, description } = getTitleAndDescription(location.pathname);

    return <Layout title={title} description={description}>{children}</Layout>;
}

function App() {
    const [csrfToken, setCsrfToken] = useState(null);

    useEffect(() => {
        // Fetch the CSRF token using the fetch API
        fetch('/api/csrf_token/')
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setCsrfToken(data.csrf_token);
        })
        .catch(error => {
            console.error('There was a problem fetching the CSRF token:', error);
        });

        const controlFooterHeight = () => {
            const w = document.documentElement.clientWidth;
            const minFooterHeight = Math.round(0.44 * w);
            document.getElementById("footerImg").style.minHeight = `${minFooterHeight}px`;
        }

        window.addEventListener("resize", controlFooterHeight);
        controlFooterHeight();

        return () => {
            window.removeEventListener("resize", controlFooterHeight);
        }
    }, []);

    return (
        <HelmetProvider>
            <Router>
                {/* Provide the CSRF token to the entire app using the context */}
                <CsrfTokenContext.Provider value={csrfToken}>
                    <LayoutWrapper>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/bookaclass" element={<BookAClassPage />} />
                                <Route path="/about" element={<AboutPage />} />
                                {/* You can add more routes as needed */}
                            </Routes>
                        </Suspense>
                    </LayoutWrapper>
                </CsrfTokenContext.Provider>
            </Router>
        </HelmetProvider>
    );
}

export default App;
