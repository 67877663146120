import React from 'react';
import { Link } from 'react-router-dom';

const NavLink = ({ title, currentTitle, path, label }) => (
    <Link 
        className={`nav-link ${currentTitle === label ? 'active' : ''}`} 
        aria-current={currentTitle === label ? 'page' : undefined} 
        to={path}
    >
        {label}
    </Link>
);

const Header = ({ title }) => {
    const navLinks = [
        { path: "/", label: "HOME" },
        { path: "/bookaclass", label: "BOOK A CLASS" },
        { path: "/about", label: "ABOUT" }
    ];

    return (
        <header>
            {title !== "HOME" && (
                <div className="text-center">
                    <Link to="/">
                        <img 
                            src="/images/SomethingCooked.png" 
                            alt="Something Cooked" 
                            className="img-fluid"  // Bootstrap class for responsive images
                            style={{ maxWidth: '200px', marginBottom: '10px' }}  // You can adjust the maxWidth to your preferred size
                        />
                    </Link>
                </div>
            )}

            <nav className="navbar navbar-expand-md navbar-light bg-white">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav ms-auto me-auto mb-2 mb-md-0">
                            {navLinks.map(link => (
                                <NavLink key={link.path} title={title} {...link} />
                            ))}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
